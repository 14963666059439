import { FC } from 'react';

import SocialMediaIcon from '@sitecore/components/content/Footer/SocialMediaIcon';
import { FooterMenuRendering } from '@sitecore/types/manual/Footer';
import { IconButton, Stack, Text, Box } from '@sparky';

const SocialMediaMenu: FC<{ fields: FooterMenuRendering['fields'] }> = ({ fields }) => {
  if (!fields.items.length) return null;

  return (
    <Stack alignX={{ initial: 'start', md: 'end' }} gap="3">
      {fields.title?.value && (
        <Stack.Item>
          <Text weight="bold">{fields.title.value}</Text>
        </Stack.Item>
      )}
      <Stack.Item>
        <Stack alignX="end" as="ul" direction="row" gap="3" wrap="true">
          {fields.items.map(navItem => {
            if (!navItem.fields.link?.value) return null;

            return (
              <Stack.Item as="li" key={navItem.id}>
                <Box backgroundColor="backgroundPrimary" borderRadius="round">
                  <IconButton
                    color="secondary"
                    href={navItem.fields.link.value.href}
                    label={navItem.fields.link.value.text || navItem.displayName}
                    size="large">
                    <SocialMediaIcon iconName={navItem.fields.iconName?.value ?? ''} />
                  </IconButton>
                </Box>
              </Stack.Item>
            );
          })}
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default SocialMediaMenu;
